// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@use "./amaplan-app-theme.scss" as palette;
// Plus imports for other components in your app.

@import "./utilities/cssHelper.scss";
@include generate-material-classes();

/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: "AmaPlan";
  src: url("assets/fonts/SF-Pro.ttf");
}

:root {
  --colors-font-primary: #343230;
  --colors-font-dark-gray: #707070;
  --colors-font-light-gray: #c4c4c4;

  --colors-amazone-green: #387d33;
  --colors-amazone-orange: #ff6900;
  --colors-amazone-orange-active: #ce6303;
  --colors-amazone-blue: #3b4e56;
  --colors-amazone-blue-active: #0b1b22;

  --colors-amazone-white: #ffffff;
  --colors-amazone-offwhite: #f4f4f4;
  --colors-amazone-light-gray: #fafafa;
  --colors-amazone-gray: #bcbcbc;

  --colors-fields-white: #ffffff;
  --colors-fields-gray: #aeaeae;
  --colors-fields-light-gray: #f0f0f0;
  --colors-fields-dark-gray: #8a8a8a;
  --colors-fields-orange: #fcb17c;

  --colors-amazone-spreadingType-normal_spreading: #ffffff;
  --colors-amazone-spreadingType-edge_spreading: #ffbf08;
  --colors-amazone-spreadingType-border_spreading: #ff5c00;
  --colors-amazone-spreadingType-dig_spreading: #930912;
  --colors-amazone-spreadingType-inside_control: #cfcfcf;
  --colors-amazone-spreadingType-patch_spreading_with_spreading_screen: #e29b55;
  --colors-amazone-spreadingType-border_spreading_with_spreading_screen: #e8e5a1;

  --colors-hint-error: #aa1212;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: AmaPlan, "Helvetica Neue", sans-serif;
}

.flexExpand {
  flex: 1 1 auto;
}

.nav-height {
  height: 87px;
  min-height: 87px;
}

.content-height {
  height: calc(100% - 87px);
  min-height: calc(100% - 87px);
}

.cursor-pointer {
  cursor: pointer;
}

/* overlay popup on the main map */

.overlay-popup {
  margin-left: -65px;
  margin-top: -85px;

  width: 129px;
  background-color: #dedede;
  border: 1px solid argb(0.5, 20, 20, 20);
  border-radius: 2px;

  padding: 2px 2px 8px 2px;

  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.4);

  pointer-events: none;

  z-index: 900;

  .overlay-popup-header {
    display: flex;
    flex-direction: row-reverse;
    font-size: 12px;
    #popup-x {
      display: grid;
      place-items: center;
      width: 18px;
      cursor: pointer;
      pointer-events: all;
    }
  }
}
.overlay-popup-btns {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
/* for the triangle: */
.overlay-popup::after {
  content: "";
  border: 20px solid transparent;
  position: absolute;

  border-bottom: 0;
  left: 50%;

  border-top-color: #ddd;
  margin-left: -65px;
}

.overlay-popup-btn {
  position: relative;

  width: 35px;
  height: 35px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 1px;

  color: #ddd;

  display: grid;
  place-items: center;

  pointer-events: all;
  cursor: pointer;
}
.overlay-popup-btn:hover {
  background-color: #efefef;
  border: 1px dashed #999;
}

/* Tooltip text */
.overlay-popup-btn .popup-tooltip {
  opacity: 0;
  width: 120px;
  background-color: rgb(34, 34, 34);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  position: absolute;
  margin-top: -114px;
  z-index: 1;

  pointer-events: none;
}

/* Show the tooltip text when you mouse over the tooltip container */
.overlay-popup-btn:hover .popup-tooltip {
  visibility: visible;
  opacity: 1;

  transition: 0.2s ease-in-out;
}

/* spreading type tooltip */
.spreading-type-btn .popup-tooltip {
  opacity: 0;
  width: 140px;
  background-color: rgb(34, 34, 34);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  position: absolute;
  margin-top: 72px;
  z-index: 6;

  pointer-events: none;
}

.popup-tooltip-centered {
  margin-left: -38px;
}
.popup-tooltip-left {
  margin-left: -70px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.spreading-type-btn:hover .popup-tooltip {
  visibility: visible;
  opacity: 1;

  transition: 0.3s ease-in-out;
  transition-delay: 0.5s;
  z-index: 6;
}

.spreading-type-btn-active .popup-tooltip {
  background-color: var(--colors-font-dark-gray);
  visibility: visible;
  opacity: 0; // change to 1 if active spreading type should always be displayed
  transition: 0.2s ease-in-out;
  z-index: 5;
  margin-top: 94px; //active spreading-type-selector-item has bigger size
}

.history-action .popup-tooltip {
  opacity: 0;
  width: 120px;
  background-color: rgb(34, 34, 34);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  position: absolute;
  margin-top: -50px;
  margin-left: -35px;
  z-index: 15;

  pointer-events: none;
}
.history-action:hover .popup-tooltip {
  visibility: visible;
  opacity: 1;

  transition: 0.2s ease-in-out;
  transition-delay: 0.75s;
}

/* generell buttons */
.btn-large {
  cursor: pointer;
  width: 362px;
  height: 48px;
  border-radius: 4px;
  gap: 10px;
  padding: 14px, 14px, 13px, 14px;
  border: 0px solid;
}
.btn-small {
  cursor: pointer;
  width: 157px;
  height: 48px;
  border-radius: 4px;
  gap: 10px;
  padding: 14px, 14px, 13px, 14px;
  border: 0px solid;
}
.btn-orange-primary {
  cursor: pointer;
  background-color: var(--colors-amazone-orange);
  color: white;
  font-size: 17px;
  font-weight: 600;
  line-height: 21px;
  border: solid;
}
.btn-orange-secondary {
  cursor: pointer;
  border: 2px solid var(--colors-amazone-orange);
  color: var(--colors-amazone-orange);
  background-color: white;
  font-size: 17px;
  font-weight: 600;
  line-height: 21px;
}

.btn-orange-primary:active {
  background-color: var(--colors-amazone-orange-active);
  color: white;
}
.btn-orange-secondary:active {
  border-color: var(--colors-amazone-orange-active);
  color: var(--colors-amazone-orange-active);
  background-color: white;
}

.btn-orange-primary:disabled {
  background-color: var(--colors-amazone-orange);
  color: white;
  opacity: 40%;
}
.btn-orange-secondary:disabled {
  border-color: var(--colors-amazone-orange);
  color: var(--colors-amazone-orange);
  background-color: white;
  opacity: 40%;
}
.btn-blue-primary {
  background-color: var(--colors-amazone-blue);
  color: white;
  font-size: 17px;
  font-weight: 600;
  line-height: 21px;
  border: 0px solid;
}
.btn-blue-secondary {
  border: 2px solid var(--colors-amazone-blue) !important;
  color: var(--colors-amazone-blue) !important;
  background-color: white !important;
  font-size: 17px;
  font-weight: 600;
  line-height: 21px;
}

.btn-blue-primary:active {
  background-color: var(--colors-amazone-blue-active);
  color: white;
}
.btn-blue-secondary:active {
  border-color: var(--colors-amazone-blue-active);
  color: var(--colors-amazone-blue-active);
  background-color: white;
}

.btn-blue-primary:disabled {
  background-color: var(--colors-amazone-blue);
  color: white;
  opacity: 40%;
}
.btn-blue-secondary:disabled {
  border-color: var(--colors-amazone-blue);
  color: var(--colors-amazone-blue);
  background-color: white;
  opacity: 40%;
}

/* overlay popup when creating a scenario */

.overlay-scenario {
  width: 90px;
  z-index: 900;
  margin-left: -45px;
  margin-top: -16px;
}
.overlay-scenario-btn {
  position: relative;

  width: 100%;
  min-height: 32px;

  background-color: #fff;
  border: 1px solid #ccc;
  color: #1d1d1d;

  display: grid;
  place-items: center;

  cursor: pointer;
}

.overlay-scenario-btn:hover {
  background-color: #efefef;
  border: 1px dashed #999;
}
/* Tooltip text */
.overlay-scenario-btn .scenario-tooltip {
  opacity: 0;
  width: 140px;
  background-color: rgb(34, 34, 34);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  position: absolute;
  margin-top: -80px;
  z-index: 1;

  pointer-events: none;
}

/* Show the tooltip text when you mouse over the tooltip container */
.overlay-scenario-btn:hover .scenario-tooltip {
  visibility: visible;
  opacity: 1;

  transition: 0.2s ease-in-out;
}

/* overlays for spreading types while creating scenarios */

.spreading-type-overlay-wrapper {
  width: 42px;
  height: 42px;
  z-index: 900;
  margin-left: -21px;
  margin-top: -21px;

  border-radius: 42px;

  display: grid;
  place-items: center;

  opacity: 0.92;
  // overflow: hidden;

  .spreading-type-overlay-item {
    background-color: white;
    border-radius: 42px;

    width: 85%;
    height: 85%;

    display: grid;
    place-items: center;
  }

  .spreading-type-overlay-item-normal_spreading {
    border: 4px solid var(--colors-amazone-gray);
  }

  .spreading-type-overlay-item-edge_spreading {
    border: 4px solid var(--colors-amazone-spreadingType-edge_spreading);
  }

  .spreading-type-overlay-item-border_spreading {
    border: 4px solid var(--colors-amazone-spreadingType-border_spreading);
  }

  .spreading-type-overlay-item-dig_spreading {
    border: 4px solid var(--colors-amazone-spreadingType-dig_spreading);
  }

  .spreading-type-overlay-item-inside_control {
    margin-right: 11px;
    margin-top: 11px;
    border-radius: 24px;
    width: 55%;
    height: 55%;
    border: 2px solid var(--colors-amazone-spreadingType-inside_control);
  }

  .spreading-type-overlay-item-border_spreading_with_spreading_screen {
    border: 4px solid
      var(--colors-amazone-spreadingType-border_spreading_with_spreading_screen);
  }

  .spreading-type-overlay-item-patch_spreading_with_spreading_screen {
    border: 4px solid
      var(--colors-amazone-spreadingType-patch_spreading_with_spreading_screen);
  }
}

.language-selector {
  margin-top: 18px;
  width: 148px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.0008em;
  text-align: center;

  // background-color: var(--colors-amazone-offwhite);
  // box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.3);
  // display: flex;
  // // align-content: space-around;
}

// mat overrides:
// search in map:
// search-field placeholder/headline when focus (otherwise primary color)
.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-floating-label,
.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-floating-label--float-above {
  color: var(--colors-amazone-dark-gray) !important;
}
// search field underline (otherwise primary color)
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: rgba(0, 0, 0, 0) !important;
}
// search field border radius
.searchfield div {
  border-radius: 2px !important;
}
.searchfield .mat-mdc-form-field-subscript-wrapper {
  height: 0px !important;
  max-height: 0px !important;
}

// element under searchfield
div.mat-mdc-autocomplete-panel {
  padding: 0px !important;
}

// search in sidebar:
.search div {
  border-radius: 2px !important;
}

.no-drag {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--colors-amazone-blue);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--colors-amazone-blue);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--colors-amazone-blue);
}

/* checkbox */
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid var(--colors-amazone-gray);
  background-color: var(--colors-amazone-light-gray);
  cursor: pointer;
}
input[type="checkbox"]:after {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  content: url("/assets/images/common/icon_checked.svg");
  display: none;
}
input[type="checkbox"]:checked:after {
  display: block;
}

.field-info-overlay {
  display: flex;
  color: white;
  font-weight: 400;
  font-size: 14px;
}
.field-info-overlay-scenario-img {
  margin-left: 4px;
  margin-top: 2px;
}

.filter-color-white {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(85deg) brightness(500%)
    contrast(104%);
}

.dialog-background {
  background-color: rgba(180, 180, 180, 0.4);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: var(--colors-amazone-blue);
  --mdc-switch-selected-handle-color: var(--colors-amazone-offwhite);
  --mdc-switch-selected-hover-state-layer-color: var(--colors-amazone-blue);
  --mdc-switch-selected-pressed-state-layer-color: var(--colors-amazone-blue);
  --mdc-switch-selected-focus-handle-color: var(--colors-amazone-offwhite);
  --mdc-switch-selected-hover-handle-color: var(--colors-amazone-offwhite);
  --mdc-switch-selected-pressed-handle-color: var(--colors-amazone-offwhite);
  --mdc-switch-selected-focus-track-color: var(--colors-amazone-blue);
  --mdc-switch-selected-hover-track-color: var(--colors-amazone-blue);
  --mdc-switch-selected-pressed-track-color: var(--colors-amazone-blue);
  --mdc-switch-selected-track-color: var(--colors-amazone-blue);

  --mdc-switch-disabled-selected-handle-color: var(--colors-amazone-offwhite);
  --mdc-switch-disabled-selected-icon-color: var(--colors-amazone-blue);
  --mdc-switch-disabled-selected-track-color: var(--colors-amazone-blue);
  --mdc-switch-disabled-unselected-handle-color: var(--colors-amazone-offwhite);
  --mdc-switch-disabled-unselected-icon-color: var(--colors-amazone-blue);
  --mdc-switch-disabled-unselected-track-color: var(--colors-amazone-blue);
}
