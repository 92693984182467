@mixin generateColors($prefix, $palette) {
   $colors-map: ();

   @each $key, $value in $palette {
       @if $key !=contrast {
       .app-#{$prefix}-#{$key} {
           color: map-get($palette, $key);
       }

       $map: ();
       $map: map-merge($map, ($key: $value));
       $colors-map: map-merge($colors-map, $map);
       }
   }

   :root {
       @each $key, $value in $colors-map {
       --app-#{$prefix}-#{$key}: #{$value};
       }
   }
}

@mixin generate-material-classes {
   @include generateColors(primary, palette.$AmaPlanPalette-primary);
   @include generateColors(accent, palette.$AmaPlanPalette-accent);
   //@include generateColors(warning, palette.$AmaPlanPalette-warning);// TODO: add red-palette from default material theme or custom one if necessary
}